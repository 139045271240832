export default function payBalance(tuition) {
  let payVal = 'Unpaid Balance';
  if (tuition) {
    if (tuition.paid || tuition.manuallyPaid) {
      payVal = 'Paid';
    } else if (tuition.manualPayment) {
      payVal = ' Paying Directly';
    } else if ((tuition.tuitionCost === 'installment' && tuition.partiallyUpdate) || tuition.manualDeposit) {
      // manualDeposit exists for 9 records that were created without the partially Webhook registered
      payVal = 'Installments';
    } else if (tuition.tuitionCost === 'installment' && !tuition.partiallyUpdate) {
      payVal = 'Unpaid Deposit';
    }
  }
  return payVal;
}
