/* eslint-disable react/button-has-type, react/prop-types */

import React from 'react';
import Modal from 'react-modal';
import { Box } from '../../style/basicStyle';
import payBalance from './payBalance';

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { modalData, isOpen, askToClose, onAfterOpen, onRequestClose } = props;
  return (
    <Modal id="viewPaymentModal" contentLabel="viewPaymentModal" closeTimeoutMS={150} isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose}>
      <h1>View Payment - {modalData.name}</h1>
      <Box>
        <h3>Parent Name: {modalData.name}</h3>
        <h3>Player Name: {modalData.playerName}</h3>
        <h3>Payment Status: {payBalance(modalData.tuition)}</h3>
        <h3>Payment Date: {modalData.paidDate}</h3>
      </Box>
      <Box>
        <button onClick={askToClose}>Cancel</button>
      </Box>
    </Modal>
  );
};
